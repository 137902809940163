import { initTranslations } from "@tokoku-universe/react-core/localization";

import enTranslations from "./common/en.json";
import idTranslations from "./common/id.json";

initTranslations({
  debug: !import.meta.env.PROD,
  resources: {
    en: enTranslations,
    id: idTranslations,
  },
});
