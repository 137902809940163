import { useLocation } from "react-router-dom";
import AnimatedOutlet from "../../components/AnimatedOutlet";
import { AnimatePresence, motion } from "framer-motion";

function PublicLayout() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.7 } }}
        exit={{ opacity: 0 }}
      >
        <AnimatedOutlet />
      </motion.div>
    </AnimatePresence>
  );
}

export default PublicLayout;
