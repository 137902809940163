import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Redemption from "../views/Redemption";
import RedemptionFailed from "../views/RedemptionFailed";
import RedemptionSuccess from "../views/RedemptionSuccess";
import PublicLayout from "../layout/PublicLayout";
import { RoutePath } from "./types";

const publicRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <PublicLayout />,
  children: [
    {
      path: RoutePath.Base,
      element: <Navigate to={RoutePath.RedemptionFailed} />,
    },
    {
      path: RoutePath.Redemption,
      element: <Redemption />,
    },
    {
      path: RoutePath.RedemptionSuccess,
      element: <RedemptionSuccess />,
    },
    {
      path: RoutePath.RedemptionFailed,
      element: <RedemptionFailed />,
    },
  ],
};

const router = createBrowserRouter([publicRoutes]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
