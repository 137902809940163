import Lottie from "lottie-react";
import { Card, Container, Typography } from "@mui/material";
import { useLocalization } from "@tokoku-universe/react-core/localization";
import { useRedemptionState } from "../../services/redemption/store";
import successAnimation from "./success.json";

function RedemptionSuccess() {
  const { t } = useLocalization();
  const { redemptionAmount, totalAmount, storeName } = useRedemptionState();

  return (
    <Container
      maxWidth="xs"
      disableGutters
      component="form"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
      }}
    >
      <Card
        sx={{
          width: "100%",
          mt: 2.5,
          p: 3.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lottie
          animationData={successAnimation}
          loop={false}
          style={{ width: 180 }}
        />
        <Typography variant="h5" mb={1}>
          {t("redemption_success.heading", { count: redemptionAmount })}
        </Typography>
        <Typography variant="body1" color="textSecondary" textAlign="center">
          {t("redemption_success.description", {
            count: totalAmount,
            storeName,
          })}
        </Typography>
      </Card>
    </Container>
  );
}

export default RedemptionSuccess;
