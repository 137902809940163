import { FormEvent, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { PhoneInput } from "@tokoku-universe/react-core/components";
import { useLocalization } from "@tokoku-universe/react-core/localization";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  FormLabel,
  Typography,
} from "@mui/material";
import TokokuBanner from "../../components/TokokuBanner";
import { RoutePath } from "../../routes/types";
import { isValidPhoneNumber } from "../../utils/validation";
import { useRedemptionState } from "../../services/redemption/store";
import { redeemLoyaltyWithToken } from "../../services/redemption";

function Redemption() {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const { token } = useParams();
  const { setTotalAmount, setRedemptionAmount, setStoreName } =
    useRedemptionState();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!token) {
      return navigate(RoutePath.RedemptionFailed, { replace: true });
    }

    setLoading(true);
    try {
      const { points, store } = await redeemLoyaltyWithToken(
        phoneNumber,
        token
      );
      const { lastAccrued, current } = points;
      setRedemptionAmount(lastAccrued);
      setTotalAmount(current);
      setStoreName(store.name);
      setLoading(false);
      navigate(RoutePath.RedemptionSuccess, { replace: true });
    } catch (e) {
      navigate(RoutePath.RedemptionFailed, { replace: true });
    }
  };

  const isValidForm = isValidPhoneNumber(phoneNumber);

  if (!token) {
    return <Navigate to={RoutePath.RedemptionFailed} replace />;
  }

  return (
    <Container
      maxWidth="xs"
      component="form"
      onSubmit={onSubmit}
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
      }}
    >
      <TokokuBanner />
      <Card sx={{ width: "100%", mt: 2.5, p: 3.5 }}>
        <Typography variant="h5" mb={3}>
          {t("redemption.heading")}
        </Typography>
        <FormLabel>{t("redemption.phone.label")}</FormLabel>
        <PhoneInput
          disabled={loading}
          sx={{ mt: 0.5, mb: 3 }}
          onClear={() => setPhoneNumber("")}
          onChangeValue={setPhoneNumber}
          value={phoneNumber}
          fullWidth
        />
        <Button
          disabled={loading || !isValidForm}
          type="submit"
          size="large"
          variant="contained"
          sx={{ display: "flex", alignItems: "center" }}
          fullWidth
        >
          {loading && (
            <CircularProgress
              sx={{ mr: 1 }}
              style={{ width: 14, height: 14 }}
            />
          )}
          {t("button.label.redeem")}
        </Button>
      </Card>
    </Container>
  );
}

export default Redemption;
