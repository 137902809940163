import { MobilePhoneLocale } from "validator/es/lib/isMobilePhone";
import { StoreCountry } from "./types";

export const mobilePhoneLocaleByCountry: Record<
  StoreCountry,
  MobilePhoneLocale
> = {
  [StoreCountry.Indonesia]: "id-ID",
  [StoreCountry.Singapore]: "en-SG",
};

export const IS_HTTP_WITH_CREDENTIALS =
  import.meta.env.VITE_APP_ENV !== "integration" &&
  import.meta.env.VITE_APP_ENV !== "development" &&
  !import.meta.env.DEV;
