import * as Sentry from "@sentry/react";
import InternalError from "../../views/InternalError";
import { ErrorBoundaryProps } from "./types";

function ErrorBoundary(props: ErrorBoundaryProps) {
  const { children } = props;

  return (
    <Sentry.ErrorBoundary fallback={<InternalError />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
