import axios from "axios";
import { createHttpClient } from "../http";
import { HTTP_BASE_URL } from "./config";
import { IS_HTTP_WITH_CREDENTIALS } from "../../config/constants";

const apiClient = axios.create({
  baseURL: HTTP_BASE_URL,
  withCredentials: IS_HTTP_WITH_CREDENTIALS,
});

export default createHttpClient(apiClient);
