import { CssBaseline } from "@mui/material";
import {
  LocalizationProvider,
  ThemeProvider,
} from "@tokoku-universe/react-core/components";
import { initSentry } from "./services/sentry";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./routes";

// init translations
import "./locales";

initSentry();

function App() {
  return (
    <LocalizationProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <CssBaseline />
          <Routes />
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
