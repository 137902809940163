import { create } from "zustand";
import { RedemptionState } from "./types";

export const useRedemptionState = create<RedemptionState>()((set) => ({
  redemptionAmount: 0,
  totalAmount: 0,
  storeName: "",
  setRedemptionAmount: (amount: number) =>
    set(() => ({ redemptionAmount: amount })),
  setTotalAmount: (amount: number) => set(() => ({ totalAmount: amount })),
  setStoreName: (storeName: string) => set(() => ({ storeName })),
}));
