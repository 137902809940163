import { Box, Typography } from "@mui/material";
import { FastokoLogo } from "@tokoku-universe/react-core/components";

function TokokuBanner() {
  return (
    <Box display="flex" alignItems="center">
      <FastokoLogo width={48} height={48} />
      <Typography variant="h4" ml={0.5} color="text.primary">
        fastoko
      </Typography>
    </Box>
  );
}

export default TokokuBanner;
